import React, { useEffect, useState } from 'react';
import Config from '../../config/config';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import LoginUser from './pharos_login';
import { Row, Col } from "react-bootstrap";

function syntaxHighlight(json) {
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return match;
    });
}

const DashboardPharos = () => {

    function setToken(userToken) {
        sessionStorage.setItem('pharosToken', JSON.parse(JSON.stringify(userToken)));
    }

    const token = sessionStorage.getItem('pharosToken');

    console.log(token)
    const ip = Config.pharosEndpoints.base;
    const pharosList = [
        { id: 1, title: 'Cloud', apiUrl: Config.pharosEndpoints.cloud.apiUrl },
        { id: 2, title: 'Config', apiUrl: Config.pharosEndpoints.config.apiUrl },
        { id: 3, title: 'Controller', apiUrl: Config.pharosEndpoints.controller.apiUrl },
        { id: 4, title: 'Project', apiUrl: Config.pharosEndpoints.project.apiUrl },
        { id: 5, title: 'Input', apiUrl: Config.pharosEndpoints.input.apiUrl },
        { id: 6, title: 'Log', apiUrl: Config.pharosEndpoints.log.apiUrl },
        { id: 7, title: 'Timeline', apiUrl: Config.pharosEndpoints.timeline.apiUrl }
    ];

    function getApi(api, title) {

        let url = ip + api;

        var requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
                "ngrok-skip-browser-warning": "1",
            },
            method: "GET",
        };

        fetch(url, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                document.getElementById("box" + title).append(syntaxHighlight(JSON.parse(JSON.stringify(json))));
            })
            .catch(function (error) {
                console.log('Request failed', error)
            });

    }

    const getList = (
        <div className="pharos-dashboard-boxes">
            <Row className="dasboard-container-row">
                {pharosList.map((post) =>
                    <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                        {getApi(post.apiUrl, post.title)}
                        <div className="pharos-category-title">{post.title}</div>
                        <div id={"box" + post.title} className="thirdparty-description"> </div>
                    </Col>
                )}
            </Row>
        </div>
    );

    if (!token || token === 'undefined') {
        { return <LoginUser setToken={setToken} /> }
    }
    return (
        <BrowseLayout>
            <div className='text-white'>Dashboard</div>
            {getList}
        </BrowseLayout>
    )
}

export default DashboardPharos
