import React, {useEffect, useState} from 'react';
import BrowseLayout from '../screens/layouts/BrowseLayout';
import connect from 'react-redux/es/connect/connect';
import { getSmartMode, getLoadingData, toggleView, getLoadingMessage, getNavigationState, getNewversion, setNewVersion, toggleSettings } from '../state/reducers/ui';
import DashboardBox from "../components/dashboard/DashboardBox";
import { Row, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getUserRole, getUserAccess } from "../state/reducers/user";
import Sidebar from '../components/Sidebar';
import Config from "../config/config";
import { KataszterController } from "../controllers/KataszterController";
import { DashboardController } from '../controllers/DashboardController';
import ExedraLogo from "../assets/logos/exedra.svg";
import BSmartLogo from "../assets/logos/b_smart.svg";
import BBqubeLogo from "../assets/logos/bbqube.svg";
import NodeguardLogo from "../assets/logos/nodeguard.svg";

/**
 * All the style object are exported from helper files.
 * Currently these are only exporting an object, but for future if we need logic in these, they can be easliy converted to functions.
 * **/

/**
 * Dashboard Componenet
 * 
 * Basic Functionality :
 * - Load all the recent and future widgets on a screen
 * - Loading mechanism until MQTT provide the necessary data, and the JSON-s are fetched
 * - 2 different view
 * 		- SMART	: All smart view widgets.
 * 		- Basic : This will be updated with widgets, and 3rd party API calls or links when requested
 * **/
const Dashboard = ({loading, loadingMessage, toggleViewState, navigationState, version, setVersion, userAccess, toggleSettings}) => {

	const [cadastreNumber, setCadastreNumber] = useState();
	const [cadastreitemNumber, setCadastreItemNumber] = useState();
	const [dashboardAccess, setDashboardAccess] = useState({smart:false, cadastres:false, administration:false, thirdparty:false, citymarketing:false, profile:false});
	const [smartDetails, setSmartDetails] = useState({items: "0", tasks: "0", last_modify: moment().format("YYYY.MM.DD")});
    const KataszterControllerInst = KataszterController.getInstance();
	const DashboardControllerInst = DashboardController.getInstance();

	toggleViewState("dashboard");
	
	useEffect(() => {
        KataszterControllerInst.getNumberOfCadastres().then(response => {
            if(response.statusCode === 200) {
				setCadastreNumber(response.data);
			}
		});
		KataszterControllerInst.getNumberOfItemsInCadastres().then(response => {
            if(response.statusCode === 200) {
				setCadastreItemNumber(response.data[0].items);
			}
		});

		DashboardControllerInst.getSmartData().then(response => {
			if(response.statusCode === 200) {
			  setSmartDetails(response.data)
			}
		  }).catch(err => {
			console.log("err", err);
		  })

		if(userAccess.hasOwnProperty("dashboard")) {
			setDashboardAccess(userAccess.dashboard);
		}
	}, []); // eslint-disable-line

	return (
		<BrowseLayout>
			{navigationState.sidebar ? 		 
				<Sidebar />
			: null}
			<div className="dashboard-container">
				<Row className="dasboard-container-row">
					{dashboardAccess.smart && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<DashboardBox title="VILÁGÍTÁSI elemek" icon="router" className="smart" type="VESZPRÉM - DATALIGHT" body="Városi világításvezérlő keretrendszer" smart={{data: [smartDetails.items, smartDetails.last_modify], detail:"elem", title:["Elemszám"]}} route="/smart" active={true} >
							<div className="logo-list">
								<div className="imageplace"><img alt="Exedra" src={ExedraLogo} /></div>
								<div className="imageplace"><img alt="Exedra" src={BSmartLogo} /></div>
							</div>
						</DashboardBox>
					</Col>}
					{dashboardAccess.cadastres && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<DashboardBox title="Kataszterek" icon="clipboard-list" className="list" type ="Veszprém ADAT" body="Azonos csoportba tartozó elemek nyilvántartása" smart={{data:[moment().format("YYYY.MM.DD")], cadastreNumber, detail:"kataszter", title:["Kataszterek"]}} route="/kataszter" active={true}>
							<div className="logo-list"></div>
						</DashboardBox>
					</Col>}
					{dashboardAccess.administration && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<DashboardBox title="Ügyintézés" icon="user-tie" className="user" type ="Veszprém MŰKÖDÉS" body="Önkormányzati ügyintézést segítő eszközök moduljai" active={true}>
							<div className="logo-list"></div>
						</DashboardBox>
					</Col>}
					{dashboardAccess.thirdparty && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<DashboardBox title="Külső szoftverkapcsolat" className="link" icon="link" type ="Veszprém  MŰKÖDÉS" body="Rendszeren kívüli programok kezelése" route='/thirdparty' active={true}>
							<div className="logo-list bottom-with-border">
								<div className="imageplace"><img alt="BBqube" src={BBqubeLogo} /></div>
								<div className="imageplace"><img alt="Nodeguard" src={NodeguardLogo} /></div>
								<div className="imageplace"><img alt="Exedra" src={ExedraLogo} /></div>
								<div className="imageplace"><img alt="B-Smart" src={BSmartLogo} /></div>
								<div className="more-points">...</div>
							</div>
						</DashboardBox>
					</Col>}
					{dashboardAccess.citymarketing && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<DashboardBox title="Városmarketing" icon="building" className="city" type ="Veszprém ADAT" body="Városmarketing elemek és működtetésük felülete" route="https://erp.smart-city.work/" active={true}>
							<div className="logo-list bottom-with-border">
								<div className="imageplace"><img alt="BBqube" src={BBqubeLogo} /></div>
								<div className="more-points">...</div>
							</div>
						</DashboardBox>
					</Col>}
					{dashboardAccess.profile && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<DashboardBox title="Gépház" icon="user" className="settings" type ="Veszprém ADAT" body="Általános beállítások és kapcsolatok" handleRouteChange={toggleSettings} route={false} active={false}>
							<div className="logo-list"></div>
						</DashboardBox>
					</Col>}
				</Row>
							
				<Row>
					<Col lg="10">
					</Col>
					<Col className="dashboard-icons" lg="2">
						<i style={{margin:"20px"}} role="button"><FontAwesomeIcon size="2x" icon={['fal', "sun"]} /></i>
						<i tyle={{margin:"20px"}} role="button"><FontAwesomeIcon size="2x" icon={['fal', "moon"]} /></i>
					</Col>
				</Row>
				<Modal show={version} onHide={() => {setVersion(false)}} dialogClassName="version-modal">
					<Modal.Header closeButton>
					<Modal.Title>Új verzió érhető el</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Config.version.message.headers.map((header, index) => {
							return(
								<div key={index}>
									<h5>{header}</h5>
									<ul>
									{Config.version.message.list[index].map(detail => {
										if(detail[0] === "-") {
											return(<ul key={index}><li key={header}>{detail.slice(1, detail.length)}</li></ul>)
										}else {
											return(<li key={header}>{detail}</li>)

										}
									})}
									</ul>
								</div>
								)
						})}

					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
				</Modal>
			</div>
		</BrowseLayout>
	);
};

Dashboard.propTypes = {};

const mapStateToProps = state => ({
	smartmode: getSmartMode(state),
	loading : getLoadingData(state),
	loadingMessage: getLoadingMessage(state),
	userRole: getUserRole(state),
	navigationState: getNavigationState(state),
	version: getNewversion(state),
	userAccess: getUserAccess(state),
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	},
	setVersion: (version) => {
		dispatch(setNewVersion(version))
	},
	toggleSettings: () => {
		dispatch(toggleSettings())
	},
  });

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(Dashboard);
