import React from 'react';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { Container } from "react-bootstrap";
import WifiDetails from "../../components/dashboard/details/wifi_details";
import EnvironmentDetails from "../../components/dashboard/details/environment_details";
import LightsDetails from "../../components/dashboard/details/lights_details";
import DefaultPage from '../../components/dashboard/details/default_page';

export default function DashboardDetails(props) {

    function getPage() {
        if(props.match.params.type === 'light') {
            return(<LightsDetails value={props.match.params.value}/>);
        }else if(props.match.params.type === 'environment') {
            return(<EnvironmentDetails value={props.match.params.value}/>);

        }else if(props.match.params.type === 'wifi') {
            return(<WifiDetails value={props.match.params.value}/>);

        }else if(props.match.params.type === 'totem') {
            window.location.replace("http://server.ki-wi.cz/kiwi-server/login");
        }
        else {
            return(<DefaultPage />);

        }
    }
    return(
        <BrowseLayout>
            <Container fluid className="detailed-container-dashboard">
                <div>
                    <div className="expanded-content chart-wrapper">
                        {getPage()}
                    </div>
                </div>
            </Container>
        </BrowseLayout>

    );
}