import React , { useState, useEffect } from 'react';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { history } from '../../state/store';
import connect from 'react-redux/es/connect/connect';
import {getLoadingData, toggleView, getLoadingMessage} from '../../state/reducers/ui';
import DashboardBox from "../../components/dashboard/DashboardBox";
import { Row, Col, Container } from "react-bootstrap";
import NewContactModal from '../_helper/newContactModal';
import { ThirdPartyController } from "../../controllers/ThirdPartyController";
import ExedraLogo from "../../assets/logos/exedra_big.svg";
import BSmartLogo from "../../assets/logos/bsmart_big.svg";
import BBqubeLogo from "../../assets/logos/bbqube_big.svg";
import NodeguardLogo from "../../assets/logos/nodeguard_big.svg";
import SchrederLogo from "../../assets/logos/schreder_logo.svg";
import DataLightLogo from "../../assets/logos/data_light_big.svg";
import PharosLogo from "../../assets/logos/pharos-logo-light.webp";


/**
 * All the style object are exported from helper files.
 * Currently these are only exporting an object, but for future if we need logic in these, they can be easliy converted to functions.
 * **/

/**
 * Dashboard Componenet
 * 
 * Basic Functionality :
 * - Load all the recent and future widgets on a screen
 * - Loading mechanism until MQTT provide the necessary data, and the JSON-s are fetched
 * - 2 different view
 * 		- SMART	: All smart view widgets.
 * 		- Basic : This will be updated with widgets, and 3rd party API calls or links when requested
 * **/
function DashboardThirdParty(props){
	props.toggleViewState("thirdparty");

	const [show, setShow] = useState(false);
	const [allData, setAllData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageLoaded, setPageLoaded] = useState(false);

    const ThirdPartyControllerInst = ThirdPartyController.getInstance();


	useEffect(() => {
		setPageLoaded(true);
	}, [])

	useEffect(() => {
		if(pageLoaded && !loading) {
			setLoading(true);
			/*ThirdPartyControllerInst.getAllThirdPartyResources().then(response => {
				if(response.statusCode === 200) {
					setAllData(response.data);
					setLoading(false);
				}
			}).catch(err => {
				console.log("error", err);
				setLoading(false);
			});*/
		}
	}, [pageLoaded]); // eslint-disable-line


	function createNewThirdParty(data) {
		ThirdPartyControllerInst.createThirdPartyResource(data).then(response => {
            if(response.statusCode === 200) {
				setAllData([...allData, {...response.data, logo:{logo:response.data.logo}}]);
				setShow(false);

            }
        }).catch(err => {
			setShow(false);
		});
	}

	function navigateToLink(route) {
        if(route) {
            if(route.includes('http')) {
                window.open(route, '_blank');
            }else {
                history.push(route)
            }
        }
    }

	return (
		<BrowseLayout>
			<div className="dashboard-container dashboard-connection-container">
				<Row className="dasboard-container-row">

					<Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6} onClick={() => { navigateToLink('/interface') }}>
						<div className="imageplace"><img alt="Exedra" src={DataLightLogo} /></div>
						<div className="thirdparty-description">DATA_LIGHT <br /> VILÁGÍTÁSVEZÉRLŐ <br /> PLUGIN</div>
						<div className="footer-info">
							<p>2020. július 06.</p>
							<p>UDG</p>
						</div>
					</Col>
					<Col className="col-new-connection" xs={12} sm={12} md={6} lg={6} xl={6}>
						<Container className="dashboard-card-body" >
							<div className="dashboard-card-inside">
								<Row className="dashboard-card-header">
									<Col lg="12" className="dashboard-header-col">
										<p className="dashboard-outside-text">külső szolgáltatás</p>
										<p className="dashboard-card-title" role="button" onClick={() => setShow(true)} >Új kapcsolat</p>
										<p className="dashboard-card-desc">
											<p>Ide kattintva új külső kapcsolat betöltését hozhatja létre</p>
											<i role="button" onClick={() => setShow(true)}>
												<span className="plus red"></span>
											</i>
										</p>
									</Col>
								</Row>
							</div>
						</Container>
					</Col>
					<Col className="col-new-connection" xs={12} sm={12} md={6} lg={6} xl={6}>
						<Container className="dashboard-card-body add-to-type">
							<div className="dashboard-card-inside">
								<Row className="dashboard-card-header">
									<Col lg="12" className="dashboard-header-col">
										<p className="dashboard-outside-text" role="button" onClick={() => setShow(true)} >külső szolgáltatás</p>
										<p className="dashboard-card-desc">
											<div className="imageplace"><img alt="Exedra" src={ExedraLogo} /></div>
											<i role="button" onClick={() => setShow(true)}>
												<span className="plus green"></span>
											</i>
										</p>
									</Col>
								</Row>
							</div>
							<div className="footer-info">
								<p>2020. július 06.</p>
								<p><img alt="Exedra" src={SchrederLogo} /></p>
							</div>
						</Container>
					</Col>
					<Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6} onClick={() => { navigateToLink('/interface') }}>
						<div className="imageplace"><img alt="Exedra" src={BBqubeLogo} /></div>
						<div className="thirdparty-description">BBQUBE <br /> NYÁRIKONYHA <br /> SZOLGÁLTATÁS <br /> PLUGIN</div>
						<div className="footer-info">
							<p>2020. július 06.</p>
							<p>Városüzemeltetés</p>
						</div>
					</Col>
				{/*	<Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6} onClick={() => { navigateToLink('/interface') }}>
						<div className="imageplace"><img alt="Exedra" src={NodeguardLogo} /></div>
						<div className="thirdparty-description">NODEGUARD <br /> INTELLIGENS <br /> KÖZLEKEDÉSVEZÉRLŐ <br /> PLUGIN</div>
					</Col>*/}
					<Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6} onClick={() => { navigateToLink('/pharos') }}>
						<div className="imageplace pharos-box"><img alt="Pharos" src={PharosLogo} /></div>
						<div className="thirdparty-description">PHAROS <br /> INTEGRÁCIÓ </div>
					</Col>
					<Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6} onClick={() => { navigateToLink('/interface') }}>
						<div className="imageplace"><img alt="Exedra" src={BSmartLogo} /></div>
						<div className="thirdparty-description">B-SMART KONTROLLER <br /> PLUGIN</div>
						<div className="footer-info">
							<p>2020. július 06.</p>
							<p>Városüzemeltetés</p>
						</div>
					</Col>
				</Row>
			</div>
		{show && <NewContactModal show={show} setShow={setShow} createNewThirdParty={createNewThirdParty}/>}
		</BrowseLayout>
	);
};

DashboardThirdParty.propTypes = {};

const mapStateToProps = state => ({
  	loading : getLoadingData(state),
	loadingMessage: getLoadingMessage(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	}
  });

export default connect(
    mapStateToProps,
	mapDispatchToProps
)(DashboardThirdParty);
