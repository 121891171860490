//Import interfaces
import Config from "../config/config";


export class DashboardController {

    private static instance: DashboardController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {
    }

    /**
     * Controlling the singleton
     */
    public static getInstance(): DashboardController {
        if (!DashboardController.instance) {
            DashboardController.instance = new DashboardController();
        }
        return DashboardController.instance;
    }

    public getWidgetData(type:any) {
        const url = Config.endpoints.base + Config.endpoints.list.dashboard.tile.replace("{type}", type);

        try {
            return new Promise((resolve, reject) => {
                fetch(url, {
                    method:"GET",
                }).then((res) => {
                    if(res.status === 200) {
                        return res.json()
                    }else {
                        reject({statusCode:500, data:"Failed to fetch data"})
                    }
                }).then(response => {
                    resolve({statusCode:200, data:response})
                }).catch(err => {
                    console.log("err", err)
                    reject({statusCode:500, data:'Failed to fetch'})
                })
            });
        } catch(error) {
            console.log("err", error)
        }
    }

    public getLampData() {
        const url = Config.endpoints.base + Config.endpoints.list.dashboard.tile.replace("{type}", "lamp");

        try {
            return new Promise((resolve, reject) => {
                fetch(url, {
                    method:"GET",
                }).then((res) => {
                    if(res.status === 200) {
                        return res.json()
                    }else {
                        reject({statusCode:500, data:"Failed to fetch data"})
                    }
                }).then(response => {
                    resolve({statusCode:200, data:response})
                }).catch(err => {
                    console.log("err", err)
                    reject({statusCode:500, data:'Failed to fetch'})
                })
            });
        } catch(error) {
            console.log("err", error)
        }
    };

    public getSmartData() {
        const url = Config.endpoints.base + Config.endpoints.list.dashboard.tile.replace("{type}", "smart");
        try {
            return new Promise((resolve, reject) => {
                fetch(url, {
                    method:"GET",
                }).then((res) => {
                    if(res.status === 200) {
                        return res.json()
                    }else {
                        reject({statusCode:500, data:"Failed to fetch data"})
                    }
                }).then(response => {
                    resolve({statusCode:200, data:response})
                }).catch(err => {
                    console.log("err", err)
                    reject({statusCode:500, data:'Failed to fetch'})
                })
            });
        } catch(error) {
            console.log("err", error)
        }
    };

    public getSummaryData(id:any) {
        let url = Config.endpoints.base + Config.endpoints.list.dashboard.tile.replace("{type}", "environment");

        if(id && id !== 'false') {
            url += '&id=' + id
        }
        try {
            return new Promise((resolve, reject) => {
                fetch(url, {
                    method:"GET",
                }).then((res) => {
                    if(res.status === 200) {
                        return res.json()
                    }else {
                        reject({statusCode:500, data:"Failed to fetch data"})
                    }
                }).then(response => {
                    resolve({statusCode:200, data:response})
                }).catch(err => {
                    console.log("err", err)
                    reject({statusCode:500, data:'Failed to fetch'})
                })
            });
        } catch(error) {
            console.log("err", error)
        }
    };

};
