import React, { useState, useEffect } from 'react';
import Box from "../../components/dashboard/Box";
import { DashboardController } from '../../controllers/DashboardController';

export default function ParkingBox(props) {
    
	const [widgets, setWidgets] = useState(false);
    const DashboardControllerInst = DashboardController.getInstance();

    useEffect(() => {
        DashboardControllerInst.getWidgetData('parking').then(response => {
            let temp = [...props.widgets];
            temp[0].value = response.data.parking_lot
            temp[1].value = response.data.reserved
            temp[2].value = response.data.parking_today
            setWidgets(temp);
        });
    }, []); // eslint-disable-line

    return (
        <Box key={0} {...props}  widgets={widgets ? widgets : props.widgets}/>
    );
};
