import React, {useState} from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';

export default function KataszterModal(props) {

    const [validateShow, setValidateShow] = useState(false)

    return(
        <React.Fragment>
        <Modal show={props.show} onHide={() => props.setShow(false)} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>Kataszter kérelem</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row className="kataszter-request-form-body-row">
                <Col lg="6">
                <p style={{paddingTop:"18px", paddingBottom:"5px"}} >Kérelmező adatai</p>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Vezetéknév</Form.Text>
                            <input type="text" className="dashboard-input-modal"></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Keresztnév</Form.Text>
                            <input type="text" className="dashboard-input-modal"></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >email</Form.Text>
                            <input type="email" className="dashboard-input-modal"></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Telefonszám</Form.Text>
                            <input type="tel" className="dashboard-input-modal"></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <p style={{paddingTop:"18px", paddingBottom:"5px"}} >Adatok</p>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Feladat tipusa</Form.Text>
                            <select name="type" id="type" className="dashboard-input-modal">
                                <option value="null"></option>
                                <option value="type1">Tipus 1</option>
                                <option value="type2">Tipus 2</option>
                                <option value="type3">Tipus3</option>
                            </select>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted" >Határidő</Form.Text>
                            <input type="date" className="dashboard-input-modal"></input>
                        </Form.Group>
                        </Col>
                    </Row>
                    <p style={{paddingTop:"12px", paddingBottom:"12px"}} >Kérelemről értesités</p>
                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicEmail">
                            <select name="name" id="name" className="dashboard-input-modal">
                                <option value="null"></option>
                                <option value="name1">Név 1</option>
                                <option value="name2">Név 2</option>
                                <option value="name3">Név 3</option>
                            </select>
                        </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col lg="6">
                    <Form.Text className="text-muted" style={{paddingTop:"65px"}}>Leirás</Form.Text>
                    <Form.Control as="textarea" rows="10" className="request-text-area"/>
                    <Form>
                    <Form.Group style={{paddingTop:"10px", paddingBottom:"10px"}}>
                        <Form.Text className="text-muted" style={{paddingTop:"10px", paddingBottom:"10px"}}>Csatolmányok</Form.Text>
                        <Form>
                        <Form.File 
                            id="custom-file"
                            label=""
                            custom
                        />
                        </Form>
                    </Form.Group>
                    </Form>
                    <button onClick={() => {
                        props.setShow(false)
                        setValidateShow(true)}} className="request-send-button">Kérelem beküldése</button>
                </Col>
            </Row>
            </Modal.Body>
        </Modal>


        <Modal show={validateShow} onHide={() => setValidateShow(false)} centered size="lg">
            <Modal.Body style={{paddingLeft:"50px", paddingRight:"50px"}}>
                <p className="kataszter-validate-header">Kataszter kérelem</p>
                <p className="kataszter-validate-body">Köszönjük, a kérelmét feldolgozzuk és hamarosan kollégáink felveszik Önnel a kapcsolatot.</p>
                <p className="kataszter-validate-body">Üdvözlettel az UMS csapata</p>
                <button onClick={() => {
                    setValidateShow(false)}
                    } className="request-send-button">Vissza az UTU-hoz</button>

            </Modal.Body>
        </Modal>
        </React.Fragment>
    );
}