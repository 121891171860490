import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import RightSideImage from "../../assets/images/interface-right-side.png";
import LeftSideContent from "../../assets/images/interface_left_data.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InterfaceContent(props) {

    return(
        <Container fluid className="interface-body">
            <Row>
                <Col className="left-container" xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className="icons-list">
                        <div className="clicked-icon"><i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i></div>
                        <div className="bottom-icons">
                            <div className="active"><i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'sign-out']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'sign-out']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'sign-out']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'sign-out']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i></div>
                            <div><i><FontAwesomeIcon size="lg" icon={['far', 'sign-out']} /></i></div>
                        </div>
                    </div>
                    <div className="left-sidebar">
                        <div className="sidebar-top-content">
                            <img src={LeftSideContent} style={{ width: '100%' }} />
                        </div>
                        <div className="sidebar-bottom"><img src={props.logo} /></div>
                    </div>
                </Col>
                <Col className="right-container" xs={12} sm={12} md={6} lg={9} xl={9}><img src={RightSideImage} style={{ width: '100%' }} /></Col>
            </Row>
        </Container>
    )
}