
//Import interfaces
import {
    loginObject, 
} from "../ts/interfaces/logincontroller_interfaces"
import Config from "../config/config";


export class LoginController {

    private static instance: LoginController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): LoginController {
        if (!LoginController.instance) {
            LoginController.instance = new LoginController();
        }
        return LoginController.instance;
    }


    /**
     * Send message to the backend with parameters: 
     * @param username
     * @param password
     */
    public login(username:string, password:string) {

        let data:loginObject = {
            identity: username,
            password: password
        }

        return new Promise((resolve, reject) => {
            const url = Config.endpoints.base + Config.endpoints.list.login;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }
    
};
