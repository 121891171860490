import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconValue from './IconValue';
import IconMultipleValue from './IconMultipleValue';
import ChartValue from './ChartValue';
import Divider from './Divider';
import SimpleValueUnit from './SimpleValueUnit';
import VerticalProgress from './VerticalProgress';
import { guid } from "../../helpers/common";
import HorizontalProgress from './HorizontalProgress';
import ImageWidget from './Image';
import DoubleIcon from './DoubleIcon';
import connect from 'react-redux/es/connect/connect';
import { getMapState, setTypeFilter, updateLoadedDynamicCadastreLayers, setAllLoadedLayers } from '../../state/reducers/ui'; 
import { ReactComponent as UtuDark } from '../../assets/images/utu_dark.svg';
import { history } from '../../state/store';
import { getUserCadastreData } from '../../state/reducers/user';
import { Row, Form, Modal, Table } from 'react-bootstrap';
import moment from 'moment';

function Box(props) {
    const { children, title, boxClass, bgColor, color, iconType, icon, widgets, logo, logo_h, top, link, redirect, detailedRoute, cadastre_id } = props;

    const [show, setShow] = useState(false);
    const [group, setGroup] = useState(false);

    function handleClick() {
        props.setTypeFilter('wifi');

        let cadastreToLoad = props.cadastreData[cadastre_id];
        props.updateLoadedDynamicCadastreLayers(cadastreToLoad);
        props.addLayer(cadastre_id);

        history.push("/map");
    }

    useEffect(() => {
        if(group === 'false') {
            props.setSelectedDevice(false);
        }
    }, [group]) // eslint-disable-line

    function rendeDevices() {
        if(group && group !== 'false') {
            props.setSelectedDevice(props.avalibleDevices[group][0])
            return props.avalibleDevices[group].map(e => {
                return(
                    <option key={e} value={e}>{e}</option>
                )
            })    
        }else {
            return(
                <option key={'Átlag'} value="false">Átlag</option>
            )
        }
    }

    return (
        <div className={ boxClass + " box-item"}>
            <div className="widget-container card">
                <div className="card-header align-items-center">
                    <div className="color-circle" style={{backgroundColor: bgColor}}>
                        <i className="icon" style={{color: color}} onClick={() => history.push(redirect)} ><FontAwesomeIcon icon={[iconType, icon]} /></i>
                    </div>
                    <span>{title}</span>
                    <p className="box-valid-date">{moment().format('YYYY-MM-DD')}</p>
                    <div className="toMapButton" onClick={handleClick}><span>Térkép</span></div>
                </div>
                <div className="card-body">
                    {title === 'Környezet' ?
                        <Form.Group controlId="ControlSelect" className="widget-device-selecter">
                            <Row style={{height:'auto'}}><Form.Label style={{fontWeight: 'bold',padding: '2px', margin: '2px'}}>Eszköz kiválasztása </Form.Label></Row>
                            <Row style={{padding: '5px 0', margin: '0', border: '10px'}}>
                                <Form.Control size="sm" as="select" style={{marginRight:'2px'}} onChange={(event) => { setGroup(event.target.value) }}>
                                        <option value="false">Átlag</option>
                                        { Object.keys(props.avalibleDevices).map(e => {
                                            return(
                                                <option key={e} value={e}>{e}</option>
                                                )})                            
                                            } 
                                </Form.Control>
                                <Form.Control size="sm" as="select" onChange={(event) => {props.setSelectedDevice(event.target.value)}}>
                                        {rendeDevices()}
                                </Form.Control>
                            </Row>
                        </Form.Group>
                    :null}
                    {title === 'Totem' ?
                        <div style={{width:'100%'}}>
                            {Object.keys(props.alerts).length ? <div className="totem-alert"> 
                                <p className="totem-alert-p">{Object.keys(props.alerts).length} szekrény ajtaja nyitva van. </p> 
                                <button onClick={() => setShow(true)}>Részletek</button>
                                </div> : null}
                        </div>
                    :null}
                    <div className="normal-widget-content">
                        <ListWidgets widgets={widgets} color={color} detailedRoute={detailedRoute} type={boxClass} data={props.data}/>
                    </div>
                </div>
                {children}
                <div className="card-footer" >
                    {logo && title !== 'Környezet' ?
                        <a href={link} target="_blank" rel="noopener noreferrer"><img alt="logo" style={{top: top+'px'}} height={logo_h} className="widget-logo" src={'logos/'+logo} /></a>
                        : ''}
                    { title === 'Környezet' ?
                        <UtuDark width="50" height={logo_h} className="widget-logo"/>
                        : ''}
                </div>
            </div>
            {title === 'Totem' && <Modal size="lg" show={show} onHide={() => setShow(false)} className="totem-alert-modal">
                <Modal.Header>
                    <Modal.Title>Jelentett hibák</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                            <th>Eszköz azonositó</th>
                            <th>Időpont</th>
                            <th>Megnevezés</th>
                            <th>Leirás</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(props.alerts).map(alert => {
                                return(
                                    <tr>
                                        <td>{alert.device_id}</td>
                                        <td>{moment.unix(alert.alert_date).format("YYYY-MM-DD hh:mm")}</td>
                                        <td>{alert.title}</td>
                                        <td>{alert.description}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                <button variant="secondary" onClick={() => setShow(false)}>
                    Bezárás
                </button>
                </Modal.Footer>
            </Modal>}
        </div>
    );
}

const ListWidgets = (props) => {
    let widgets = props.widgets;
    const color = props.color;

    // if(props.type === 'box-wifi') {
    //     widgets[0].value = props.data.totalAps + '/' + props.data.aps
    // }

    const components = {
        'IconValue': IconValue,
        'IconMultipleValue': IconMultipleValue,
        'SimpleValueUnit': SimpleValueUnit,
        'ChartValue': ChartValue,
        'Divider': Divider,
        'VerticalProgress':VerticalProgress,
        'HorizontalProgress':HorizontalProgress,
        'DoubleIcon':DoubleIcon,
        'ImageWidget':ImageWidget
    };

    const widgetItems = widgets.map((widget) => {
        const key = guid();
        const data = {...widget,color};
        const TagName = components[data.widget];
        return  <TagName key={key} {...data} detailedRoute={props.detailedRoute}/>
    
    });

    return (
        widgetItems
    );
}

const mapStateToProps = state => ({
    mapState: getMapState(state),
    cadastreData : getUserCadastreData(state),
});

const mapDispatchToProps = (dispatch) => ({
    setTypeFilter: (type) => {
        dispatch(setTypeFilter(type));
    },
    updateLoadedDynamicCadastreLayers : (cadastre) => {
		dispatch(updateLoadedDynamicCadastreLayers(cadastre))
	},
    addLayer: (selected) => {
        dispatch(setAllLoadedLayers(selected));
    },
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Box);
