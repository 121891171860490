import React from 'react';
import { Bar } from 'react-chartjs-2'

export default function StackedbarChart(props) {

    const data = {
        labels: props.labels,
        datasets: [
            {
                label: 'határérték',
                data: props.values.map(x => {
                    return(215)
                }),
                backgroundColor: '#b0b0b0',
            },
            {
                label: 'határérték felett',
                data: props.values,
                backgroundColor: 'rgb(255, 99, 132)',

            },
        ],
    }
      
      const options = {
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                        // gridLines: {
                        //     color: ["#dedede", "#87de62", "#dedede"],
                        // }
                    },
                ],
                xAxes: [
                    {
                    stacked: true,
                    barPercentage: 0.4
                    },
                ],
            }
      }

    return(
        <div>
            <div className="chartinfo-multiline">
                <p>{props.title}</p>
            </div>
            <div style={{height:"300px"}}>
              <Bar data={data} options={options} height={300}/>
          </div>
        </div>
    );
}
