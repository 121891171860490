export const parking = [
	{
		'widget': 'IconValue',
		'size': 'half',
		'value': "0",
		'icon': 'map-marker-times',
		'unit': '',
		'label': 'Parkolók száma'
	},
	{
		'widget': 'IconValue',
		'size': 'half last',
		'value': "0",
		'icon': 'cars',
		'unit': '',
		'label': 'Foglalt parkolók'
	},
	{
		'widget': 'IconValue',
		'size': 'full',
		'value': "0",
		'icon': 'parking-circle',
		'unit': '',
		'label': 'Parkolások (ma)'
	},
	{
		'widget': 'Divider',
		'size': 'full',
		'label': 'Hunyadi téri parkoló'
	},
	{
		'widget': 'VerticalProgress',
		'size': 'half border',
		'label': '15m',
		'value': '35',
		'unit': '%',
	},
	{
		'widget': 'VerticalProgress',
		'size': 'half last border',
		'label': '15m-1h',
		'value': '30',
		'unit': '%',
	},
	{
		'widget': 'VerticalProgress',
		'size': 'half',
		'label': '1h-3h',
		'value': '25',
		'unit': '%',
	},
	{
		'widget': 'VerticalProgress',
		'size': 'half last',
		'label': '3h+',
		'value': '10',
		'unit': '%',
	}
];