import React, { useEffect } from 'react';
import { ConnectedRouter} from 'connected-react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactPiwik from 'react-piwik';

import './App.css';

/* layout definitions */
import AppLayout from './screens/layouts/AppLayout';
import MainLayout from './screens/layouts/MainLayout';

import NoMatch from './screens/NoMatch';
import Login from './screens/Login';
import Map from './screens/Map';
import Dashboard from './screens/Dashboard';
import Projects from './screens/Projects';
import { history } from './state/store';
import Config from './config/config';

//temp
import Dashboard_smart from "./screens/dashboard_screens/dashboard_smart";
import Dashboard_kataszter from "./screens/dashboard_screens/dashboard_kataszter";
import Dashboard_thirdparty from "./screens/dashboard_screens/dashboard_thirdparty";
import Dashboard_details from "./screens/dashboard_screens/dashboard_details";
import DashboardCityMarketing from "./screens/dashboard_screens/dashboard_citymarketing";
import DashboardThirdpartyDetails from "./screens/dashboard_screens/dashboard_thirdparty_details";
import dashboard_pharos from './screens/dashboard_screens/dashboard_pharos';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { faParticles, faSensors,faLights,faTotem,faSmartready, faSmart } from './assets/icons/CustomIcons';

import withAuth from "./helpers/auth";

library.add(fal);
library.add(far);
library.add(fas);
library.add(faParticles);
library.add(faSensors);
library.add(faLights);
library.add(faTotem);
library.add(faSmartready);
library.add(faSmart);


const piwik = new ReactPiwik(Config.piwik);

function App() {
    ReactPiwik.push(['enableHeartBeatTimer']);
    ReactPiwik.push(['trackPageView']);

    useEffect(() => {
        console.log("%cUTU urban interface", "color:#7abebf; font-size:16px; font-weight:500");
        console.log("%cCopyright 2021.", "font-size:9px");
        console.log("%cUrban Management Solution Kft.", "color:#7abebf; font-size:12px");
        console.log("%cTovábbi információért,keress fel bennünket: www.urbanms.hu", "font-size:10px");
    }, [])

    return (
            <ConnectedRouter history={piwik.connectToHistory(history)}>
                    <Route component={AppLayout}>
                        <Switch>
                            <Route exact path="/" component={() => <Redirect to="/login" />}/>
                            <Route component={MainLayout}>
                                <Switch>
                                    <Route path="/login" component={withAuth(Login)} breadcrumbName={'common:breadcrumbs.Settings'} />
                                    <Route path="/dashboard" component={withAuth(Dashboard)}/>

                                    <Route path="/smart/:type/:value" component={withAuth(Dashboard_details)}/>
                                    <Route path="/smart" component={withAuth(Dashboard_smart)} />

                                    <Route path="/kataszter" component={withAuth(Dashboard_kataszter)} />
                                    <Route path="/thirdparty" component={withAuth(Dashboard_thirdparty)} />
                                    <Route path="/interface" component={withAuth(DashboardThirdpartyDetails)} />
                                    <Route path="/citymarketing" component={withAuth(DashboardCityMarketing)}/>
                                    <Route path="/pharos" component={withAuth(dashboard_pharos)} />

                                    <Route path="/map" component={withAuth(Map)} breadcrumbName={'common:breadcrumbs.Dashboard'} />
                                    <Route path="/project" component={withAuth(Projects)} breadcrumbName={'common:breadcrumbs.Projects'} />
                                    <Route path="*" component={withAuth(NoMatch)} breadcrumbName={'common:breadcrumbs.page-not-found'} title="404" />
                                </Switch>
                            </Route>
                        </Switch>
                    </Route>
            </ConnectedRouter>
    );
}

export default App;
