import React, {useState, useEffect} from 'react';
import Config from '../../../config/config';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { KataszterController } from '../../../controllers/KataszterController';
import {getUserCadastreData} from '../../../state/reducers/user';
import connect from 'react-redux/es/connect/connect';
import DropZoneUpload from '../../../helpers/DropZoneUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

function Information(props){

    const [popup, setPopup] = useState({visible: false, imageUrl: '', rotation:0});
    const [selected, setSelected] = useState({});
    const [warning, setWarning] = useState("");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false); // eslint-disable-line
    const [imageIds, setImageIds] = useState([]);
    const [deleteRef, setDeleteRef] = useState(false);
    const [files, setFiles] = useState([]);

    //Setup base URL for the pictures folder.
    const imgURL = Config.static.img;
    const thumbnailURL = Config.static.imgThumbnail;
    const KataszterControllerInst = KataszterController.getInstance();

    useEffect(() => {
        setWarning("");
        setFiles(props.data.files ? props.data.files : []);
        props.cadastre_details.forEach(detail => {
            let variable_name = false;
            if(detail.hasOwnProperty('visible')) {
                if(detail.visible.visible_details) {
                    variable_name = {name:detail.name, label:detail.label, position:detail.visible.order_header}
                }
            }

            if(variable_name) {
                if(props.data.hasOwnProperty(variable_name.name)) {
                    setData((prevState) => ([...prevState, {...variable_name, value:props.data[variable_name.name]}]));
                }
                else if(props.data.json) {
                    if(props.data.json.hasOwnProperty(variable_name.name)) {
                        setData((prevState) => ([...prevState, {...variable_name, value:props.data.json[variable_name.name]}]));
                    }
                }
            }
        });

        if(props.data.hasOwnProperty('images')) {
            setImageIds(props.data.images ? props.data.images : []);
        }
    }, []) // eslint-disable-line


    function getValue(key, element) {
        let value = element.value
        if(selected.hasOwnProperty(props.updateId)) {
            if(selected[props.updateId].hasOwnProperty(key)) {
                value = selected[props.updateId][key]
            }
            else {
                value = element.value
            }
        }
        return value;
    }

    function setupModifiableData(formData, element) {
        if(formData.input === "string" || formData.input === "number"){
            return(
                <tr >
                    <td style={{fontSize: "12px", fontWeight:"600"}}>{formData.label}</td>
                    <td>
                        <input type={formData.input === "string" ? "text" : "number"} name={formData.name} className="small form-control filter-search" placeholder="Keresés" value={getValue(element.name, element)} onChange={(event) => {updateSelected(event.target.value, props.updateId, formData.name)}}/>
                    </td>
                </tr>
            );
        }
        else if(formData.input === "list") {
            return(
                <tr >
                    <td style={{fontSize: "12px", fontWeight:"600"}} >{formData.label}</td>
                    <td>
                        <select className="form-control " onChange={(event) => {updateSelected(event.target.value, props.updateId, formData.name)}} value={getValue(formData.name, element)}>
                            <option value="null">---</option>
                            {Object.entries(formData.values).map(([key, value]) => {
                                return(
                                    <option value={key}>{value}</option>
                                )
                            })}
                        </select>
                    </td>
                </tr>);
        }
        else {
            return(<tr key={formData.name}><td className="key">{formData.label}:</td><td>{ formData.input === 'date' ? moment.unix(element.value).format("YYYY-MM-DD hh:mm"): element.value}</td></tr>)
        }
    }

    function updateSelected(value, id, key) {
        let temp = selected;
        if(selected.hasOwnProperty(id)) {
            if(selected[id].hasOwnProperty(key)) {
                if(value === "null" || value === "") {
                    delete temp[id][key];
                    if(!Object.keys(temp[id]).length){
                        delete temp[id];
                    }
                }else {
                    let obj = {}
                    obj[key] = value;
    
                    temp[id] = {
                        ...temp[id],
                        ...obj
                    }
                }
            }else {
                temp[id][key] = value
            }
        }else {
            let obj = {}
            obj[key] = value;

            temp[id] = {
                ...obj
            }
        }
        setSelected({...temp});
    }

    function updateTasks() {
        setLoadingData(true);
        let updateData = selected[props.updateId];
        let temp = {};

        props.cadastre_details.filter(x => x.editable || x.required).forEach(x => {
            temp[x.name] = updateData.hasOwnProperty(x.name) ? updateData[x.name] : props.data.json[x.name]
        });


        KataszterControllerInst.updateKataszterById(props.updateId, temp, props.data.loc.coordinates).then(response => {
            if(response.statusCode === 200) {
                let temp = [];
                data.forEach(element => {
                    if(Object.keys(updateData).includes(element.name)) {
                        let i = element;
                        i.value = updateData[element.name]
                        temp.push(i);
                    }else {
                        temp.push(element);
                    }
                });
                setData(temp);
            }else {
                setWarning("Az adatokat nem sikerült frissiteni.")
            }
            setLoadingData(false);
            props.updateSelected(null)
        })
    }

    function loadData() {
        let form_json = props.cadastreData[props.data.cadastre_id].form_json;

        return(data.map(element => {
            let formData = form_json.find(x => x.name === element.name);
            if(formData.editable && props.updateId === props.data.id) {
                return(
                    setupModifiableData(formData, element)
                )
            }else {
                return(
                    <tr key={element.name}><td className="key">{element.label}:</td><td>{formData.input === 'list' ? formData.values[element.value] : formData.input === 'date' ? moment.unix(element.value).format("YYYY-MM-DD hh:mm") : element.value }</td></tr>
                )
            }

        }));
    }

    function deleteFile(ref) {

        KataszterControllerInst.deleteFile(ref).then(() => {
            setFiles(files.filter(x => x.id !== ref));
            setDeleteRef(false);

        }).catch(() => {
            setDeleteRef(false);
            setWarning("Sikertelen törlés.")
        });
    }
    function addFile(uploaded) {
        setFiles([...files, ...uploaded[0]]);
    }

    return (
      <div>
      <div className="infotable-wrapper">
       <div className="infotable row">
         <div className="col-md-12">
         	  <div className="row">
              <div className="col-md-9">
                <h5>Készülék</h5>
                <div className="table-wrapper">
                  <table className={props.updateId === props.data.id ? "edit-table" : ""}>
                    <tbody>
                        {data.length ?
                            loadData()
                        :
                            <p>Nincs megjelenitendő adat.</p>
                        }
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="images">
                            {imageIds.length ? (
                                <div className="image">
                                <h5>Képek</h5>
                                <div className="img-grid">
                                    {imageIds.map((image) => (
                                        <div key={image.file} onClick={() => setPopup({
                                                visible: true,
                                                imageUrl: imgURL + image.file,
                                                rotation: image.rotation
                                            })} className="img" style={{backgroundImage: `url(${thumbnailURL + image.file})`, transform: 'rotate(' + image.th_rotation + 'deg)'}} />
                                    ))}
                                </div>
                                </div>
                            ) : <p>Nincsenek képek</p>}
                        </div>
                    </div>
                    {files.length ?
                        <div className="col-md-12 file-container">
                            <h5>Fájlok</h5>
                            {files.map(file => {
                                const url =  file.ref ? Config.endpoints.base + Config.endpoints.list.kataszter.file.get.replace('{ref}', file.ref) : "https://bekescsaba.manageyourcity.com/pdfs/" + file.file;
                                return(
                                    <Row style={{height:'auto'}}>
                                        <Col lg={10}>
                                            <a className="cadastre-uploaded-file" rel="noopener noreferrer" target="_blank" href={url} title={file.file} download>{file.desc !== "" ? file.desc : file.file}</a>
                                        </Col>
                                        {file.ref && <Col lg={2} style={{float:'right'}}>
                                            <FontAwesomeIcon role="button" onClick={() => setDeleteRef(file.ref)} size="1x" color="#ff6969" icon={['fal', 'trash-alt']} />
                                        </Col>}
                                        {deleteRef === file.ref ? 
                                            <Col lg={12} className="file-remove">
                                                <div className="remove-warning">
                                                    <p>Biztosan törli a fájlt?</p>
                                                    <button className="yes" onClick={() => deleteFile(file.id)}>Igen</button>
                                                    <button className="no" onClick={() => setDeleteRef(false)}>Nem</button>
                                                </div>
                                            </Col>:null}
                                    </Row>
                                );
                            })}
                        </div>: null}
                    <div className="col-md-12 upload-file-container">
                        <DropZoneUpload data={props.data} type="cadastre" addFile={addFile} />
                    </div>  
                </div>
              </div>
              {warning !== "" &&
                <div><p>{warning}</p></div>
              }
              <div className="acceptbuttons">
                {props.updateId === props.data.id && <Button variant="primary" onClick={() => updateTasks()} disabled={!selected.hasOwnProperty(props.updateId)}>Változtatások mentése</Button>}
                {props.updateId === props.data.id && <Button variant="danger" onClick={() => props.updateSelected(null)}>Vissza</Button>}
            </div>
            </div>
         </div>
       </div>
       <Modal className="confirm img-modal" show={popup.visible} onHide={() => setPopup({visible: false, imageUrl: popup.imageUrl})} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
            <img
            className="img"
            src={popup.imageUrl}
            alt={props.data.fkj_fa_id}
            style={{"height" : "auto", "width" : "100%", transform:'rotate(' + popup.rotation + 'deg)'}}
            />
          </Modal.Body>
          <Modal.Footer>
              <Button variant="danger" onClick={() => {setPopup({visible: false, imageUrl: popup.imageUrl})}}>Bezár</Button>
          </Modal.Footer>
        </Modal>
      </div>
      </div>
    );
}

const mapStateToProps = state => ({
    cadastreData : getUserCadastreData(state),
  
});
  
const mapDispatchToProps = () => ({

});
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Information);