import React from 'react';
import LoginContainer from '../containers/Login'

const Login = () => {
    const position = [46.6774182,21.0926272];
    const center = [46.6774182,21.037];

    return (
        <LoginContainer position={position} center={center} />
    );
};

Login.propTypes = {};

export default Login;
