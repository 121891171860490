import React, {useEffect, useState} from 'react';
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LineChart from '../charts/LineChart';
import MultiLineChart from '../charts/MultiLineChart';
import { MeasurementController } from '../../../controllers/MeasurementController';
import moment from 'moment';
import WifiTable from './wifi_table/WifiTable';

export default function WifiDeatils(props) {

    const [wifiData, setWifiData] = useState({});
    const [totalUsedData, setTotalUsedData] = useState(0); // eslint-disable-line
    const [wifiApsData, setWifiApsData] = useState({aps:[], total_traffic:0});
    const [loading, setLoading] = useState(false);
    const [dateType, setDateType] = useState(1)

    const MeasurementControllerInst = MeasurementController.getInstance();

    useEffect(() => {
        let from = moment().subtract(dateType, 'days').valueOf()
        let to = moment().valueOf()
        setLoading(true);


        MeasurementControllerInst.getWifiHistoricalData(from, to).then(response => {
            if(response.statusCode === 200) {
                setWifiData(response.data.traffic);
            }
        }).catch(err => {
            console.log("err", err)
        });

        MeasurementControllerInst.getWifiApsData().then(response => {
            setLoading(false);
            if(response.statusCode === 200) {
                setWifiApsData(response.data)
            }
        }).catch(err => {
            setLoading(false);
            console.log('err', err)
        });

    }, [dateType]) // eslint-disable-line

    
    const wifiMonth =["Jan","Feb","Már","Ápr","Máj","Jún","Júl","Aug","Szep","Okt","Nov","Dec"];
    const yearMin= [1,2,3,6,8,10,23,4,9,11,5,12,13,20];
    const yearMax= [20,1,7,0,8,11,23,30,19,23,24,26,29];
    const yearAvg= [5,9,25,12,13,8,0,7,17,4,5,7,8,10,12];

    //temp table
    const access_colums = React.useMemo(
        () => [
            {
                Header: 'Állapot',
                accessor: 'status',
                Cell: ({ row, value }) => (
                    <div title="Probléma bejelentése">
                        <i role="button" style={{color: value === 'Online' ? "#64ed6a" : "#f06060"}} >
                            <FontAwesomeIcon icon={['fas', 'circle']} />
                        </i>
                    </div>),
            },
            {
                Header: 'Hatásterület',
                accessor: 'hatasterulet',
            },
            {
                Header: 'Helyszin',
                accessor: 'location.location',
            },
            {
                Header: 'Eszközazonositó',
                accessor: 'name',
            },
            {
                Header: 'Sorozatszám',
                accessor: 'serial',
            },
            {
                Header: 'Csatlakozott kliensek',
                accessor: 'clients',
            },
            {
                Header: () => null, 
                id: 'details',
                Cell: ({ row, value }) => (<a className="datasheet">Adatlap</a>),
            },
            ],
        []
    );


    const location_data = React.useMemo(
        () => [
          {
            active: true,
            place: '1',
            desc: 'Sétáló utca public wifi hozzáférések',
            ap: '1',
            connected: '21',

          },
          {
            active: true,
            place: '2',
            desc: 'public wifi hozzáférések',
            ap: '2',
            connected: '3',
          },
          {
            active: true,
            place: '3',
            desc: 'public wifi hozzáférések',
            ap: '3',
            connected: '53',
          },
          {
            active: false,
            place: '3',
            desc: 'Sétáló utca public wifi hozzáférések',
            ap: '31',
            connected: '51',
          },
        ],
        []
      )


    const location_colums = React.useMemo(
        () => [
            {
                Header: 'Állapot',
                accessor: 'active',
                Cell: ({ row, value }) => (
                    <div title="Probléma bejelentése">
                        <i role="button" style={{color: value ? "#64ed6a" : "#f06060"}} >
                            <FontAwesomeIcon icon={['fas', 'circle']} />
                        </i>
                    </div>),
            },
            {
                Header: 'Hatásterület',
                accessor: 'place',
            },
            {
                Header: 'Leirás',
                accessor: 'desc',
            },
            {
                Header: 'AP-k száma',
                accessor: 'ap',
            },
            {
                Header: 'Csatlakozott kliensek',
                accessor: 'connected',
            },
            {
                Header: () => null, 
                id: 'details',
                Cell: ({ row, value }) => (<a className="datasheet">Adatlap</a>),
            },
            ],
        []
    );


    return(
        <Tabs defaultActiveKey={props.value}>
            <Tab eventKey="10" title="Alap adatok">
            {loading &&
                        <div className="loader-container" style={{padding:'20px', position: "absolute"}}>
                            <div className="loader"></div>
                        </div>
                    }
                <Tab.Content className="detailed-wifi-body">
                    <Row>
                        <Col lg={3} className="detailed-wifi-data">
                            <Row>
                                <Col lg={12} className="detailed-wifi-current">
                                    <Row>
                                        <Col lg={12}>
                                            <i className="icon"><FontAwesomeIcon size="4x" color="#83f4bc" icon={['far', 'router']} /></i>
                                            <p>{totalUsedData.toFixed(2)} MB</p>
                                            <h4>Összes<br />adatforgalom</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="detailed-wifi-data-chart">
                                    <Row>
                                        <Col lg={12}>
                                            <i className="icon"><FontAwesomeIcon size="4x" color="#83f4bc" icon={['far', 'mobile-alt']} /></i>
                                            <p>{ wifiApsData.aps.length } db</p>
                                            <h4>Hozzáférési<br />pontok</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="detailed-wifi-data-chart">
                                    <Row>
                                    <Col lg={12} className="data-map">
                                        <i className="icon"><FontAwesomeIcon size="3x" icon={['fal', 'map-marked-alt']} /></i>
                                        <h4>WiFi hozzáférési pontok megjelenítése a térképen</h4>
                                    </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={9} className="detailed-wifi-chart">
                            <Row>
                                <Col lg={12} >
                                    <LineChart dateType={dateType} setDateType={setDateType} data={Object.values(wifiData).map(x => x.total/1000000000)} labels={Object.keys(wifiData)} step={30} hide={1} fill={true} title={"Adatforgalom (GB)"} types={[{title:"Napi", value:1}, {title:"Heti", value:7}, {title:"Havi", value:30}]}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} >
                                    <MultiLineChart dateType={dateType} setDateType={setDateType} data={{min:yearMin, max:yearMax, avg:yearAvg}} labelName={["Napi max átlag", "Napi min átlag", "Napi átlag"]} labels={wifiMonth} fill={false} title={"Elöző évi adatok"} types={[]}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Tab.Content>
            </Tab>
            <Tab eventKey="11" title="Helyszínek">
                <Tab.Content className="detailed-wifi-body-table">
                    <WifiTable loading={loading} data={location_data} columns={location_colums}/>
                </Tab.Content>
            </Tab>
            <Tab eventKey="12" title="Hozzáférési pontok (AP)">
                <Tab.Content className="detailed-wifi-body-table">
                    <WifiTable loading={loading} data={wifiApsData.aps} columns={access_colums}/>
                </Tab.Content>
            </Tab>
        </Tabs>
    );    
}