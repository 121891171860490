import update from 'immutability-helper';
import Cookies from 'universal-cookie';

const md5 = require('md5');
let cookies = new Cookies();

const INITIAL_STATE = {
    auth: {
        username: sessionStorage.username ? sessionStorage.username : null,
        password: sessionStorage.password ? sessionStorage.password : null,
        access: sessionStorage.userAccess ? JSON.parse(sessionStorage.userAccess) : {}

    },
    login: {
        warning:null,
    },
    settings: {
        map:{
            cadastreStyles:{},
            savedFilters:cookies.get('usersettings') ? cookies.get('usersettings').map.savedFilters : {}
        },
        cadastre:{
            favourite:cookies.get('usersettings') ? cookies.get('usersettings').cadastre.favourite : []
        },
        project:{}
    },
    rights: sessionStorage.rights ? JSON.parse(sessionStorage.rights) : {},
    userDetails : sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails) : {},
    token: sessionStorage.token ? sessionStorage.token : null,
    projects: sessionStorage.projects ? JSON.parse(sessionStorage.projects) : null,
    cadastreData: localStorage.getItem('cadastreData') ? JSON.parse(localStorage.getItem('cadastreData')) : null,
    role: sessionStorage.role ? sessionStorage.role : null,
    cadastreAuth: sessionStorage.cadastreAuth ? JSON.parse(sessionStorage.cadastreAuth) : {},
    photo: sessionStorage.photo ? sessionStorage.photo : null,
};

export const SET_USER_AUTH = 'SET_USER_AUTH';
export const ADD_USER_AUTH_WARNING = 'ADD_USER_AUTH_WARNING';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const CLEAR_USER_AUTH_WARNING = 'CLEAR_USER_AUTH_WARNING';
export const SET_USER_CADASTRE_AUTH = 'SET_USER_CADASTRE_AUTH';
export const SET_USER_CADASTRE_DATA = 'SET_USER_CADASTRE_DATA';
export const RESET = 'RESET';
export const SET_USER_PHOTO = 'SET_USER_PHOTO';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_DYNAMIC_CADASTRE_LAYER_STYLE = 'SET_DYNAMIC_CADASTRE_LAYER_STYLE';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USER_FAV_CADSTRE = 'SET_USER_FAV_CADSTRE';
export const SET_USER_ACCESS = 'SET_USER_ACCESS';
export const SET_USER_RIGHTS = 'SET_USER_RIGHTS';



const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_USER_AUTH:
            return update(state, {
                auth: {
                    username: { $set: payload.username },
                    password: { $set: payload.isMD5 ? payload.password : md5(payload.password) },
                    id: {$set: payload.user_id}
                }
            });
        case ADD_USER_AUTH_WARNING:
            return update(state, {
                login: {
                    warning: { $set: payload.warning }
                }
            });
        case CLEAR_USER_AUTH_WARNING:
            return update(state, {
                login: {
                    warning: { $set: null }
                }
            });
        case SET_USER_TOKEN:
            return update(state, {
                token: {
                    $set: payload.token 
                }
            });
        case SET_USER_PROJECTS:
            return update(state, {
                projects: {
                    $set: payload.projects
                }
            });
        case SET_USER_ROLE:
            return update(state, {
                role: {
                    $set: payload.role 
                }
            });
        case SET_USER_CADASTRE_AUTH:
            return update(state, {
                cadastreAuth: {
                    $set: payload.cadastreAuth 
                }
            });
        case SET_USER_CADASTRE_DATA:
            return update(state, {
                cadastreData: {
                    $set: payload.cadastreData
                }
            });
        case SET_USER_PHOTO:
            return update(state, {
                photo: {
                    $set: payload.photo 
                }
            });
        case SET_USER_DETAILS:
            return update(state, {
                userDetails: {
                    $set: payload.details 
                }
            });
        
        case SET_DYNAMIC_CADASTRE_LAYER_STYLE:
            return(update(state, {
                cadastreData: {
                    $apply: (current) => {
                        current[payload.cadastre.id].style = payload.cadastre.style
                        return current
                    }
                }
            }));
        case SET_USER_SETTINGS:
            return(update(state, {
                settings: { 
                    $apply: (current) => {
                        cookies.set('usersettings', payload.settings, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
                        return payload.settings
                    }
                }
            }));

        case SET_USER_FAV_CADSTRE:
            return(update(state, {
                settings: {
                    cadastre: {
                        favourite: {
                            $apply: (current) => {
                                if(current.includes(payload.cadastre)) {
                                    return current.filter(x => x !== payload.cadastre)
                                }else {
                                    return [...current, payload.cadastre]
                                }
                            }
                        }
                    }
                }
            }));
        case SET_USER_ACCESS:
            return(update(state, {
                auth: {
                    access: { $set: payload.access  }
                }
            }));
        case SET_USER_RIGHTS:
            return(update(state, {
                rights: {
                    $set: payload.rights
                }
            }))
        case RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default userReducer;

export const setUserSettings = (settings) => ({
    type: SET_USER_SETTINGS,
    payload: { settings }
});

export const setUserAuth = (username, password, isMD5, user_id) => ({
    type: SET_USER_AUTH,
    payload: { username, password, isMD5, user_id }
});

export const addUserAuthWarning = (warning) => ({
    type: ADD_USER_AUTH_WARNING,
    payload: { warning }
});

export const addUserToken = (token) => ({
    type: SET_USER_TOKEN,
    payload: { token }
});

export const addUserProjects = (projects) => ({
    type: SET_USER_PROJECTS,
    payload: { projects }
});

export const addUserRole = (role) => ({
    type: SET_USER_ROLE,
    payload: { role }
});

export const clearUserAuthWarning = () => ({
    type: CLEAR_USER_AUTH_WARNING,
    payload: {  }
});

export const setUserCadastreAuth = (cadastreAuth) => ({
    type: SET_USER_CADASTRE_AUTH,
    payload: { cadastreAuth }
});

export const setUserCadastreData = (cadastreData) => ({
    type: SET_USER_CADASTRE_DATA,
    payload: { cadastreData }
});

export const setUserPhoto = (photo) => ({
    type: SET_USER_PHOTO,
    payload: { photo }
});

export const setUserDetails = (details) => ({
    type: SET_USER_DETAILS,
    payload: { details }
});

export const resetUserSettings = () => ({
    type:RESET,
    payload: { }
});

export const setLoadedDynamicCadastreLayerStyle = (cadastre) => ({
    type:SET_DYNAMIC_CADASTRE_LAYER_STYLE,
    payload: {cadastre}
});

export const setUserFavCadastre = (cadastre) => ({
    type:SET_USER_FAV_CADSTRE,
    payload:{cadastre}
});

export const setUserAccess = (access) => ({
    type:SET_USER_ACCESS,
    payload:{access}
});

export const setUserRights = (rights) => ({
    type:SET_USER_RIGHTS,
    payload:{rights}
})

export const getUserName = (state) => state.user.auth.username;
export const getUserPass = (state) => state.user.auth.password;
export const getUserId = (state) => state.user.auth.id;
export const getUserToken = (state) => state.user.token;
export const getUserProjects = (state) => state.user.projects;
export const getUserRole = (state) => state.user.role;
export const getUserAuth = (state) => state.user.auth;
export const getUserLoginWarning = (state) => state.user.login.warning;
export const getUserCadastreAuth = (state) => state.user.cadastreAuth;
export const getUserCadastreData = (state) => state.user.cadastreData;
export const getUserPhoto = (state) => state.user.photo;
export const getUserDetails = (state) => state.user.userDetails;
export const getUserSettings = (state) => state.user.settings;
export const getUserAccess = (state) => state.user.auth.access;
export const getUserRights = (state) => state.user.rights;