import Config from "../config/config"
import moment from 'moment';

export class ExedraAPIController {
    private static instance: ExedraAPIController

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): ExedraAPIController {
        if (!ExedraAPIController.instance) {
            ExedraAPIController.instance = new ExedraAPIController()
        }
        return ExedraAPIController.instance
    }

    public getConsumption(limit: number, period: "Day" | "Week" | "Month") {
	const today = new Date().toISOString()
        const startDay = moment().subtract(limit,'d').toISOString()
        const url = `${Config.endpoints.exedraAPIBase}${Config.endpoints.exedraAPIEndpoints.consumption}?from=${startDay}&to=${today}&limit=${limit}&period=${period}`
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.error) {
                        resolve({ statusCode: 401, data: data.error.message })
                    } else {
                        resolve({ statusCode: 200, data: data })
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch consumption", error)
                })
        })
    }

    public getFailures() {
        const url = `${Config.endpoints.exedraAPIBase}${Config.endpoints.exedraAPIEndpoints.failures}`

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.error) {
                        resolve({ statusCode: 401, data: data.error.message })
                    } else {
                        resolve({ statusCode: 200, data: data })
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch consumption", error)
                })
        })
    }
}
