import React , { useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';
//import NewProject from '../../components/Project/NewProject';

import { getProjectState, 
		getNavigationState, 
		projectTypeChanged, 
		setListFile,
		setTableFile,
		projectSidebarToggle,
		addMapFilter,
		removeMapFilter, 
		getFilterOptions, 
		setSidebarType,
		getSidebarType,
		getSidebarLayout,
		setSidebarLayout
} from '../../state/reducers/ui';
import { getUserRole, getUserCadastreData } from '../../state/reducers/user';

import ProjectSidebarHeader from "./sidebar/ProjectSidebarHeader";
import ProjectSidebarBody from "./sidebar/ProjectSidebarBody";
import ProjectSidebarIcon from "./sidebar/ProjectSidebarIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExportDataModal from './_helper/exportDataModal';

function ProjectSidebar(props) {

	const [viewChange, setViewChange] = useState(false);
	const [wasNew, setWasNew] = useState(false)
	const [reset, setReset] = useState(false);
	const [show,setShow] = useState(false);
	const [projectCadastres, setProjectCadastres] = useState([]);

	const sidebarTypes = {map:props.userRole !== 'USER' ? ['filter', 'layer', 'project', 'tile'] : ['layer'], project:['filter']}

	useEffect(() => {
		setProjectCadastres(Object.values(props.cadastreData).filter(x => {  // eslint-disable-line
			if(x.projects && x.projects.map(p => p.id).includes(props.selectedProject)) {
				return x;
			}
		}));
	}, [props.selectedProject]);  // eslint-disable-line

	useEffect(() => {
		if(props.projectState.category === "new") {
			setWasNew(true);
			if(props.navigationState.projectSidebar) {
				props.projectSidebarToggle()
			}
		}else {
			if(!props.navigationState.projectSidebar && wasNew) {
				props.projectSidebarToggle()
				setWasNew(false)
			}
		}

		setViewChange(false);
	}, [props, viewChange, wasNew]);


	useEffect(() => {
		if(props.type === "project" && props.sidebarType === "layer") {
			props.setSidebarType("filter")
		}
	}, []);  // eslint-disable-line

	const headerType = props.sidebarType === "filter" || props.sidebarType === "closed" || props.type === "project" ? "filter" : (props.type === 'map' && props.sidebarType === "closed") || (props.type === 'map' && props.sidebarType === "layer") ?  "layer" : 'project';
    return (
    <React.Fragment>
		<div className={'project-sidebar-wrapper card closed'} style={{height:window.innerHeight - 100, 'Zindex': '12'}}>
			{sidebarTypes[props.type].map((type, index) => {
				return(
					<div key={index}>
						<ProjectSidebarIcon index={index} projectSidebarToggle={props.projectSidebarToggle}  setActiveLayout={props.setSidebarLayout} activeLayout={props.sidebarLayout} type={type} action={type} navState={props.navigationState} />
					</div>
				);
			})}
			{props.type === 'project' &&
			<div>
				<div title="Export készítése" className="project-sidebar-export sidebar-tooltip">
					<i role="button" onClick={() => setShow(true)}><FontAwesomeIcon size="lg" icon={['fas', 'file-alt']}/></i>
				</div>
			</div>
			}
		</div>
		{props.navigationState.projectSidebar && 
			<div className="project-sidebar-wrapper card" style={{marginRight:"50px"}}>
				<ProjectSidebarHeader projectSidebarToggle={props.projectSidebarToggle} setActiveLayout={props.setSidebarLayout} activeLayout={props.sidebarLayout} type={props.type} action={headerType} />
				<ProjectSidebarBody 
					loadSavedFilterMissingLayers={props.loadSavedFilterMissingLayers} 
					loadedLayers={props.loadedLayers} 
					loadProject={props.loadProject} 
					type={props.type} 
					setReset={setReset} 
					reset={reset} 
					setActiveLayout={props.setSidebarLayout} 
					activeLayout={props.sidebarLayout} 
					loadLayer={props.loadLayer} 
					modifyStyle={props.modifyStyle} 
					loadedProjects={props.loadedProjects}
					setEnableCluster={props.setEnableCluster}
					enableCluster={props.enableCluster}
					loadTileLayer={props.loadTileLayer}
					setProjectCluster={props.setProjectCluster}
					projectCluster={props.projectCluster}
					loadedElements={props.loadedElements}
					loadingTasks={props.loadingTasks}
					projectCadastres={projectCadastres}
					loadTitleLayers={props.loadTitleLayers}
					/>
			</div>
		}
		<ExportDataModal type='project' show={show} setShow={setShow} />
	</React.Fragment>
    );
};

ProjectSidebar.propTypes = {};

const mapStateToProps = state => ({
	filterOptions: getFilterOptions(state),
 	navigationState: getNavigationState(state),
	projectState: getProjectState(state),
	sidebarType: getSidebarType(state),
	sidebarLayout : getSidebarLayout(state),
	userRole: getUserRole(state),
	cadastreData: getUserCadastreData(state),
});

const mapDispatchToProps = (dispatch) => ({
  setProjectType: (projecttype) => {
    dispatch(projectTypeChanged(projecttype));
  },
  setListFile: (file) => {
    dispatch(setListFile(file));
  },
  setTableFile: (file) => {
    dispatch(setTableFile(file));
  },
  projectSidebarToggle: () => {
  	dispatch(projectSidebarToggle());
  },
  addMapFilter: (filterObj) => {
	dispatch(addMapFilter(filterObj));
  },
  removeMapFilter: () => {
	  dispatch(removeMapFilter());
  },
  setSidebarType: (type) => {
	dispatch(setSidebarType(type));
  },
  setSidebarLayout : (layout) => {
	  dispatch(setSidebarLayout(layout))
  }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProjectSidebar);
