import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Col, Row, FormCheck } from 'react-bootstrap';
import FilterComponent from "../Filter";
import connect from 'react-redux/es/connect/connect';
import { getProjectState, getNavigationState, projectTypeChanged, getLoadingData, getLoadingMessage, setLoadingData } from '../../../state/reducers/ui';
import {getUserProjects} from "../../../state/reducers/user";
import CadastreLayerConstructor from '../sidebar/CadastreLayerConstructor';
import { getUserCadastreAuth } from "../../../state/reducers/user";
import { KataszterController } from "../../../controllers/KataszterController";
import { MapController } from "../../../controllers/MapController";

import CadastreFilterComponent from '../sidebar/CadastreFilterComponent';
import MapConfigSidebar from '../../Map/ui/MapConfigSidebar';
import TileSidebar from './TileSidebar';
import Config from "../../../config/config";

function ProjectSidebarBody(props) {

    const [selectedLayer, setSelectedLayer] = useState(0)
    const isFilterActive = (props.activeLayout === "filter");
    const isLayersActive = (props.activeLayout === "layer");
    const isProjectsActive = (props.activeLayout === "project");
    const isTilesActive = (props.activeLayout === "tile");
    const [selected, setSelected] = useState(["tile"]);
    const [layerTiles, setLayerTiles] = useState([]);
    const [tiles, setTiles] = useState([]);

    const [allCadastre, setAllCadastre] = useState([]);
    const [allCadastreGroups, setAllCadastreGroups] = useState([]);
    const [enableCadastreFilter, setEnableCadastreFilter] = useState(false);

    //for loader
    const [loadingCadastreGroups, setLoadingCadastreGroups] = useState(false);

    const KataszterControllerInst = KataszterController.getInstance();
    const MapControllerInst = MapController.getInstance();


    //Only filter active on project view
    useEffect(() => {
        if(props.type === 'project') {
            props.setActiveLayout('filter');
        }
    }, [props.type]); // eslint-disable-line

    useEffect(() => {
        if(props.loadedProjects) {
            setSelected([...selected, ...props.loadedProjects])
        }
    }, [props.loadedProjects]) // eslint-disable-line

    useEffect(() => {
        setLoadingCadastreGroups(true);
        props.setLoadingData(true)
        KataszterControllerInst.getAllCadastre().then(response => {
            if(response.statusCode === 200) {
                setAllCadastre(response.data.filter(x => {
                    setLoadingCadastreGroups(false)
                    return true;
                    //return props.cadastreAuth.hasOwnProperty(x.cadastre_ref)
                }));
                
            }
        });

        KataszterControllerInst.getAllCadastreGroups().then(response => {
            if(response.statusCode === 200) {
                setLoadingCadastreGroups(false);
                setAllCadastreGroups(response.data)
            }
        });

        MapControllerInst.getLayerTiles(Config.map.location).then(response => {
            if(response.statusCode === 200) {
                setLayerTiles(response.data)
            }
            props.setLoadingData(false)
        }).catch(err => {
            setLoadingData(false);
        });

        MapControllerInst.getTiles(Config.map.location).then(response => {
            if(response.statusCode === 200) {
                setTiles(response.data)
            }
        }).catch(err => {
            console.log('err', err)
        });

    }, []) // eslint-disable-line


    //Dummy function for project load
    function loadProjectMiddleWare(tile, projectid) {
        if(tile) {
            props.loadTileLayer(tile);
        }
        else {
            props.loadProject(projectid);
        }
    };

    function handleTitleCadastreLoad(layer) {
        props.loadTitleLayers(layer);
    }
  
    return(
        <div className="project-settings-wrapper">
            <div className="view-filters">
                {isFilterActive && <>
                    <div className="project-filters float-box">
                        {props.projectState.category === "kozvil" && props.navigationState.view !== 'map' && <FilterComponent page={props.type} type={""} setReset={props.setReset} reset={props.reset} loadSavedFilterMissingLayers={props.loadSavedFilterMissingLayers} projectCadastres={props.projectCadastres}/>}
                        {props.navigationState.view === 'map' && <MapConfigSidebar loadedElements={props.loadedElements} page={props.type} type={""} setReset={props.setReset} reset={props.reset} loadSavedFilterMissingLayers={props.loadSavedFilterMissingLayers} projectCadastres={props.projectCadastres}/>}
                    </div>
                </>}

                {isLayersActive && <div className="project-sidebar-layer-switch">
                    {/* Load layer select options if layer header selected */}
                    {!enableCadastreFilter ? <CadastreLayerConstructor 
                        allCadastre={allCadastre} 
                        allCadastreGroups={allCadastreGroups} 
                        selectedLayer={selectedLayer} 
                        loadedLayers={props.loadedLayers} 
                        loadLayer={props.loadLayer} 
                        setSelectedLayer={setSelectedLayer} 
                        setEnableCluster={props.setEnableCluster}
                        enableCluster={props.enableCluster}
                        enableCadastreFilter={setEnableCadastreFilter}
                        loadingCadastreGroups={loadingCadastreGroups}
                        />
                        :
                        <CadastreFilterComponent setEnableFilter={setEnableCadastreFilter} cadastre={enableCadastreFilter} modifyStyle={props.modifyStyle} header={true}/>}
                </div>}


                {isProjectsActive && props.type === 'map' && <div className="project-sidebar-layer-switch">
                    {/* Load layer select options if layer header selected */}
                    <button style={{marginBottom:"20px", backgroundColor:props.projectCluster ? "#ff7a7a" : "#63c965", padding:"3px"}} disabled={props.loadingTasks} onClick={() => props.setProjectCluster()}>Cluster</button>
                    {props.loadedProjects.length ?	
			        <div className="info-legend">
				        <ul>
					        <li className="approved-style"><img src="markers/week_13-16.png" alt="marker.png"/>Elvégezve</li>  
                            <li className="done-style"><img src="markers/week_9-12.png" alt="marker.png"/>Jóváhagyva</li>    
                            <li className="app-style"><img src="markers/week_5-8.png" alt="marker.png"/>Elfogadva</li>  
				        </ul>
			        </div>
                    :
                    null}
                    {Object.entries(props.projects).map(([projectid, details]) => {
                        return(
                            <Row>
                                <Col lg={2} xs={2}>
                                    <FontAwesomeIcon icon={['fas', details.icon]} role="button" onClick={() => loadProjectMiddleWare(false)}/>
                                </Col>
                                <Col lg={7} xs={7}>
                                    <p>{details.title}</p>
                                </Col>
                                <Col lg={3} xs={3}>
                                    <FormCheck
                                        id={"switch"+ projectid}
                                        type="switch"
                                        label=""
                                        checked={selected.includes(projectid)}
                                        disabled={props.loadingTasks}
                                        onChange={() => {
                                            loadProjectMiddleWare(false, projectid);
                                            selected.includes(projectid) ? setSelected(selected.filter(x => x !== projectid)) : setSelected([...selected, projectid])
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </div>}
                {isTilesActive && <div className="project-sidebar-layer-switch">
                    <TileSidebar
                        tiles={tiles}
                        selected={selected} 
                        setSelected={setSelected}
                        loadedLayers={props.loadedLayers} 
                        loadTile={loadProjectMiddleWare} 
                        handleTitleCadastreLoad={handleTitleCadastreLoad} 
                        layerTiles={layerTiles}
                        loading={props.loading}
                        />
                    </div>}
                <div>
                    {props.loading &&
                        <div className="loader-container" style={{marginLeft: "25%"}}>
                            <div className="loader"></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
 	navigationState: getNavigationState(state),
    projectState: getProjectState(state),
    projects: getUserProjects(state),
    loading : getLoadingData(state),
    loadingMessage: getLoadingMessage(state),
    cadastreAuth: getUserCadastreAuth(state),
});

const mapDispatchToProps = (dispatch) => ({
    setProjectType: (projecttype) => {
      dispatch(projectTypeChanged(projecttype));
    },
    setLoadingData: (loadingData) => {
        dispatch(setLoadingData(loadingData))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProjectSidebarBody);
